<template>
  <div class="input-group row no-gutters">
    <slot />
  </div>
</template>

<script>
export default {
  name: "FormGroup",
};
</script>

<style>
</style>